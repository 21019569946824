<template>
  <div>
    <b-overlay :show="isShowLoading">
      <b-card :title="$t('master.compliance.form')">

        <validation-observer ref="validateForm" #default="{invalid}">
          <b-form @submit.prevent="saveData">
            <b-row>
              <b-col cols="4" md="2" lg="1">
                <b-form-group :label="$t('master.compliance.num') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('master.compliance.num')" rules="required">
                    <b-form-input v-model="complianceTypeNum" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('master.compliance.typeName') + ' [TH]*'">
                  <validation-provider #default="{ errors }" :name="$t('master.compliance.typeName')" rules="required">
                    <b-form-input v-model="complianceTypeNameTh" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('master.compliance.typeName') + ' [EN]'">
                    <b-form-input v-model="complianceTypeNameEn"/>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row align-v="center" class="my-2">
              <b-col cols="12" md="6">
                <h4>{{ $t('master.compliance.name') }}</h4>
              </b-col>

              <b-col cols="12" md="6" class="text-right">
                <b-button variant="primary" @click="addRow">{{ $t('master.compliance.btnAddCompliance') }}</b-button>
              </b-col>
            </b-row>

            <b-row v-for="(row, key) in complianceLists" :key="key" align-v="center" class="compliance-row">
              <b-col cols="4" order="1" md="2" lg="1">
                <b-form-group :label="$t('master.compliance.num')">
                  <validation-provider #default="{ errors }" :name="$t('master.compliance.num')" rules="required|integer" :vid="'complianceNum' + key">
                    <b-form-input v-model="row.complianceNum"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" order="3" md="6" lg="5" order-lg="2">
                <b-form-group :label="$t('master.compliance.name') + ' [TH]*'">
                  <validation-provider #default="{ errors }" :name="$t('master.compliance.name')" rules="required" :vid="'complianceNameTh' + key">
                    <b-form-input v-model="row.complianceNameTh" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" order="4" md="6" lg="5" order-lg="3">
                <b-form-group :label="$t('master.compliance.name') + ' [EN]'">
                  <b-form-input v-model="row.complianceNameEn"/>
                </b-form-group>
              </b-col>

              <b-col cols="8" order="2" md="10" lg="1" order-lg="4" class="text-right">
                <b-button variant="danger" size="sm" @click="deleteRow(key)" v-if="complianceLists.length > 1">
                  {{ $t('master.compliance.btnDeleteCompliance') }}
                </b-button>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="text-center">
                <b-button type="submit" variant="success" class="my-1" :disabled="isBtnDisabled">
                  <b-spinner small v-if="isBtnDisabled"/>
                  <feather-icon icon="SaveIcon" v-else/>
                  {{ $t('general.btnSubmit') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

      </b-card>
    </b-overlay>
  </div>

</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BSpinner, BForm, BButton, BOverlay} from 'bootstrap-vue'
import {required, integer} from '@validations'
import formMixins from "@/mixins/formMixins"
import {ComplianceService} from "@/services"

const complianceService = new ComplianceService()

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    BOverlay,
  },
  mixins: [formMixins],
  async created() {
    this.id = this.$route.params.id

    if(this.id) {
      await this.getData()
    } else {
      this.addRow()
    }

    this.isShowLoading = false
    this.enabledCheckBackButton()
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    id: '',
    complianceTypeNum: '',
    complianceTypeNameTh: '',
    complianceTypeNameEn: '',
    complianceLists: []
  }),
  methods: {
    async getData() {
      let queryResult = await complianceService.getData(this.id)
      if(queryResult.isSuccess) {
        let {data} = queryResult
        let {result} = data
        this.complianceTypeNum = result.complianceTypeNum
        this.complianceTypeNameTh = result.complianceTypeNameTh
        this.complianceTypeNameEn = result.complianceTypeNameEn
        this.complianceLists = result.complianceLists
      } else {
        this.$store.commit('main/setToastError', queryResult.data)
        await this.$router.push({name: 'pageMasterCompliance'})
      }
    },
    saveData() {
      this.$refs.validateForm.validate().then(async success => {
        if (success) {
          this.isBtnDisabled = true
          let actionData = {
            complianceTypeNum: this.complianceTypeNum,
            complianceTypeNameTh: this.complianceTypeNameTh,
            complianceTypeNameEn: this.complianceTypeNameEn,
            complianceLists: this.complianceLists,
          }
          let saveResult

          if (this.id) {
            saveResult = await complianceService.updateData(this.id, actionData)
          } else {
            saveResult = await complianceService.createData(actionData)
          }

          this.isBtnDisabled = false

          if (saveResult.isSuccess) {
            this.$store.commit('main/setToastSuccess', {title: 'Save success', text: ''})
            await this.$router.push({name: 'pageMasterCompliance'})
          } else {
            this.$store.commit('main/setToastError', saveResult.data)
          }
        }
      })
    },
    addRow() {
      this.complianceLists.push({
        complianceId: '',
        complianceNameTh: '',
        complianceNameEn: '',
        complianceNum: this.complianceLists.length + 1,
      })
    },
    deleteRow(row) {
      this.complianceLists.splice(row, 1)
      this.complianceLists = this.complianceLists.map((data, key) => ({...data, complianceNum: key + 1}))
    }
  }
}
</script>

<style lang="scss">
.compliance-row {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 25px;
  padding-bottom: 10px;
}
</style>
