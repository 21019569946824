var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.isShowLoading}},[_c('b-card',{attrs:{"title":_vm.$t('master.compliance.form')}},[_c('validation-observer',{ref:"validateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveData($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4","md":"2","lg":"1"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.compliance.num') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.compliance.num'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.complianceTypeNum),callback:function ($$v) {_vm.complianceTypeNum=$$v},expression:"complianceTypeNum"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.compliance.typeName') + ' [TH]*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.compliance.typeName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.complianceTypeNameTh),callback:function ($$v) {_vm.complianceTypeNameTh=$$v},expression:"complianceTypeNameTh"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.compliance.typeName') + ' [EN]'}},[_c('b-form-input',{model:{value:(_vm.complianceTypeNameEn),callback:function ($$v) {_vm.complianceTypeNameEn=$$v},expression:"complianceTypeNameEn"}})],1)],1)],1),_c('b-row',{staticClass:"my-2",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',[_vm._v(_vm._s(_vm.$t('master.compliance.name')))])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"12","md":"6"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.addRow}},[_vm._v(_vm._s(_vm.$t('master.compliance.btnAddCompliance')))])],1)],1),_vm._l((_vm.complianceLists),function(row,key){return _c('b-row',{key:key,staticClass:"compliance-row",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"4","order":"1","md":"2","lg":"1"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.compliance.num')}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.compliance.num'),"rules":"required|integer","vid":'complianceNum' + key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(row.complianceNum),callback:function ($$v) {_vm.$set(row, "complianceNum", $$v)},expression:"row.complianceNum"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","order":"3","md":"6","lg":"5","order-lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.compliance.name') + ' [TH]*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.compliance.name'),"rules":"required","vid":'complianceNameTh' + key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(row.complianceNameTh),callback:function ($$v) {_vm.$set(row, "complianceNameTh", $$v)},expression:"row.complianceNameTh"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","order":"4","md":"6","lg":"5","order-lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.compliance.name') + ' [EN]'}},[_c('b-form-input',{model:{value:(row.complianceNameEn),callback:function ($$v) {_vm.$set(row, "complianceNameEn", $$v)},expression:"row.complianceNameEn"}})],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"8","order":"2","md":"10","lg":"1","order-lg":"4"}},[(_vm.complianceLists.length > 1)?_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.deleteRow(key)}}},[_vm._v(" "+_vm._s(_vm.$t('master.compliance.btnDeleteCompliance'))+" ")]):_vm._e()],1)],1)}),_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('b-button',{staticClass:"my-1",attrs:{"type":"submit","variant":"success","disabled":_vm.isBtnDisabled}},[(_vm.isBtnDisabled)?_c('b-spinner',{attrs:{"small":""}}):_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.$t('general.btnSubmit'))+" ")],1)],1)],1)],2)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }